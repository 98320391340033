<template>
  <div id="app">
    <div v-if="$route.path == '/dataCenter'">
      <router-view />
    </div>
    <div class="appChild" v-else>
      <div
        v-show="showheadFoot"
        class="header"
        :class="{ header_bg_color: showNavigation }"
      >
        <div class="header_bg">
          <div @click="toPage('home')">
            <div
              v-if="logoTitle && logoTitle.companyId != 3"
              style="display: flex; align-items: center"
            >
              <div class="header-img"></div>
              <div class="header_title">
                {{ logoTitle && logoTitle.companyName }}
              </div>
            </div>
            <div class="header_img1" v-else></div>
          </div>
          <div style="display: flex; align-items: center" v-if="toCompanyId">
            <div class="header-center">
              <div
                v-for="item in pageList"
                :key="item.id"
                @mouseleave="mouseLeaveMenu(item)"
                @mouseover="mouseOverMenu(item)"
                class="header-text-outside"
                :class="{ 'header-text-outside-active': nav_id == item.id }"
              >
                <div
                  :class="{ 'header-text-border': nav_id == item.id }"
                  class="header-text"
                  @click="nav_click(item.id, item.path)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div
              class="header_phone"
              v-if="
                $route.path == '' ||
                $route.path == '/' ||
                $route.path == '/ProvincialCompany' ||
                $route.path == '/cityCompany' ||
                $route.path == '/areaCompany'
              "
            >
              <img
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-29/987a2e6c0bb849c3ab8d4aa33f097449.png"
                width="150px"
                @click="toOtherPage('https://www.zoneyu.cn/')"
              />
            </div>
            <div class="header_phone" v-else>
              全国统一服务热线<br />400-185-3168
            </div>
          </div>
          <div class="header-center" style="margin-right: 45px" v-else>
            <div
              v-for="item in pageList"
              :key="item.id"
              @mouseleave="mouseLeaveMenu(item)"
              @mouseover="mouseOverMenu(item)"
              class="header-text-outside"
              :class="{ 'header-text-outside-active': nav_id == item.id }"
            >
              <div
                :class="{ 'header-text-border': nav_id == item.id }"
                class="header-text"
                @click="nav_click(item.id, item.path)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
          <div
            class="header_phone"
            v-if="
              ($route.path == '' ||
                $route.path == '/' ||
                $route.path == '/ProvincialCompany' ||
                $route.path == '/cityCompany' ||
                $route.path == '/areaCompany') &&
              !toCompanyId
            "
          >
            <img
              src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-29/987a2e6c0bb849c3ab8d4aa33f097449.png"
              width="150px"
              @click="toOtherPage('https://www.zoneyu.cn/')"
            />
          </div>
          <div class="header_phone" v-else-if="!toCompanyId">
            <div>全国统一服务热线<br />400-185-3168</div>
          </div>
        </div>
      </div>
      <div class="navBox topMenu">
        <div
          class="navigation"
          @mouseleave="mouseLeaveMenu1()"
          @mouseover="mouseOverMenu1()"
        >
          <div
            class="navigation_list"
            v-for="(item, index) in treeData"
            :key="item.companyId"
          >
            <!-- width="100%" -->

            <div
              style="position: relative"
              class="moveTop"
              @click="toPage('other', item)"
            >
              <img :src="item.companyImage" />
              <div :key="index" class="home_four_module_bg"></div>
              <div class="fontSize">
                {{ item.companyName }}
              </div>
            </div>
            <!-- <div v-for="(item1, index) in item.treeChildData" :key="item1.companyId">
              <div class="navigation_list_title moveTop" @click="toPage('/ProvincialCompany', item)">
                <div>{{ item1.companyName }}</div>
                <div><i class="el-icon-arrow-right"></i></div>
              </div>
              <div class="navigation_name">
                <div class="navigation_name_item moveTop" v-for="(item2, index) in item1.treeChildData"
                  :key="item2.companyId" @click="toPage('/ProvincialCompany', item2)">
                  <span style="font-size: 12px">●</span>&nbsp;&nbsp;{{
                    item2.companyName
                  }}
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="company_title topMenu"
        @mouseleave="mouseLeaveMenu2()"
        @mouseover="mouseOverMenu2()"
      >
        <div class="company_title_outSide">
          <div
            class="company_title_item"
            @mouseover="movebgImg(0, 0)"
            @click="
              toPage1(titleType == 8 ? '/introduction' : '/newsList', {
                id: 0,
              })
            "
          >
            <!-- @click="toPage1((titleType == 3 ? '/introduction' : '/newsList'), { id: 0 })"> -->
            {{ titleType == 8 ? "企业简介" : "新闻资讯" }}
          </div>
          <div
            class="company_title_item"
            @mouseover="movebgImg(214, 1)"
            @click="
              toPage1(titleType == 8 ? '/introduction' : '/newsList', { id: 1 })
            "
          >
            {{ titleType == 8 ? "全国网点" : "媒体资讯" }}
          </div>
          <div
            class="company_title_item"
            @mouseover="movebgImg(428, 2)"
            @click="
              toPage1(titleType == 8 ? '/introduction' : '/newsList', { id: 2 })
            "
          >
            {{ titleType == 8 ? "下属单位" : "行业资讯" }}
          </div>
          <div
            class="company_title_item"
            @mouseover="movebgImg(642, 3)"
            @click="
              toPage1(titleType == 8 ? '/introduction' : '/newsList', { id: 3 })
            "
          >
            {{ titleType == 8 ? "文化理念" : "热点专题" }}
          </div>
        </div>
      </div>
      <router-view />
      <div class="foot_" v-show="showheadFoot">
        <div class="foot_top_box">
          <div class="foot_top_box_content">
            <div class="foot_center_box">
              <ul v-for="item in list" :key="item.id">
                <div class="foot_center_box_head">
                  <span
                    @click="toPage1(item.content.url1, item.content.query1)"
                    >{{ item.content.content1 }}</span
                  >
                </div>
                <li class="link">
                  <span
                    class="moveTop"
                    @click="toPage1(item.content.url2, item.content.query2)"
                    >{{ item.content.content2 }}</span
                  ><span
                    v-show="item.together"
                    class="moveTop"
                    @click="toPage1(item.content.url21, item.content.query21)"
                    >{{ item.content.content21 }}</span
                  >
                </li>
                <li class="link">
                  <span
                    class="moveTop"
                    @click="toPage1(item.content.url3, item.content.query3)"
                    >{{ item.content.content3 }}</span
                  ><span
                    v-show="item.together"
                    class="moveTop"
                    @click="toPage1(item.content.url31, item.content.query31)"
                    >{{ item.content.content31 }}</span
                  >
                </li>
                <li class="link">
                  <span
                    class="moveTop"
                    @click="toPage1(item.content.url4, item.content.query4)"
                    >{{ item.content.content4 }}</span
                  ><span
                    v-show="item.together"
                    class="moveTop"
                    @click="toPage1(item.content.url41, item.content.query41)"
                    >{{ item.content.content41 }}</span
                  >
                </li>
                <li class="link">
                  <span
                    class="moveTop"
                    @click="toPage1(item.content.url5, item.content.query5)"
                    >{{ item.content.content5 }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="foot_center_left_box">
              <div class="foot_center_left_box_left" style="text-align: center">
                <img
                  src="../static/image/foot_logo.png"
                  width="80px"
                  style="margin-bottom: 35px"
                />
                <img src="../static/newImg2/qrcode.jpg" width="122px" />
                <div
                  style="
                    font-size: 14px;
                    color: #fff;
                    margin-top: 17px;
                    text-align: center;
                  "
                >
                  企业公众号
                </div>
              </div>
              <div class="foot_center_left_box_right">
                <div class="foot_center_left_box_right_title">联系方式：</div>
                <div class="foot_center_left_box_right_value">400-185-3168</div>
                <div class="foot_center_left_box_right_title">联系地址：</div>
                <div class="foot_center_left_box_right_value">
                  浙江省绍兴市柯桥区金柯大道金昌国际金融中心(金柯大道东100米)2楼207号
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="foot_bottom_box">
          <div>
            <a
              target="_blank"
              href="https://beian.miit.gov.cn"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
              ><img src="../static/image/beian.png" style="float: left" />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: rgba(255, 255, 255, 0.56);
                "
              >
                浙ICP备2020034128号-2 &nbsp;&nbsp;&nbsp;&nbsp;
              </p> </a
            ><a
              target="_blank"
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302001254"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
            >
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: rgba(255, 255, 255, 0.56);
                "
              >
                浙公网安备33060302001254号
              </p>
            </a>
            <p
              style="
                color: rgba(255, 255, 255, 0.56);
                font-size: 12px;
                text-align: center;
                display: inline-block;
                margin: 0px 0px 0px 16px;
                vertical-align: super;
              "
            >
              中峪道地药材 版权所有
            </p>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div
        class="contactUs"
        :class="isMobile ? 'contactUs_phone' : ''"
        v-show="showContactUs && !toCompanyId"
      >
        <div class="contactUs_right">
          <div class="contactUs_value">
            <div
              class="contactUs_value_text"
              @mouseover="showQRcode = true"
              @mouseleave="showQRcode = false"
            >
              联系我们
            </div>
            <div class="contactUs_left" v-if="showQRcode">
              <div class="contactUs_left_name">服务热线</div>
              <div class="contactUs_left_value">400-185-3168</div>
              <img src="../static/newImg2/qrcode.jpg" width="150px" />
              <div class="contactUs_left_tips">中峪道地药材</div>
            </div>
          </div>
          <div class="contactUs_top" @click="scrollToTop">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
      <!-- 去集团 -->
      <div
        class="contactUs1"
        :class="isMobile ? 'contactUs_phone' : ''"
        v-show="showContactUs1 && toCompanyId"
      >
        <div class="contactUs_right1">
          <div class="contactUs_value1">
            <div
              class="contactUs_value_text1"
              @click="onGroup(0)"
              style="line-height: 20px; opacity: 1"
              :style="
                isMobile && !toParentType && logoTitle.companyLevel > 2
                  ? 'line-height: 64px;'
                  : 'line-height: 18px;'
              "
            >
              {{
                isMobile && !toParentType && logoTitle.companyLevel > 2
                  ? "展开"
                  : "返回农业集团"
              }}
            </div>
            <div
              v-if="
                (logoTitle.companyLevel >= 3 && !isMobile) ||
                (logoTitle.companyLevel >= 3 && toParentType)
              "
              :class="
                logoTitle.companyLevel >= 3
                  ? 'contactUs_value_text2'
                  : isMobile
                  ? 'contactUs_value_text2'
                  : ''
              "
              class="contactUs_value_text1 contactUs_value_text1_right"
              @click="onGroup(1)"
            >
              返回省公司
            </div>
            <div
              v-if="
                (logoTitle.companyLevel >= 4 && !isMobile) ||
                (logoTitle.companyLevel >= 4 && toParentType)
              "
              :class="
                logoTitle.companyLevel >= 4
                  ? 'contactUs_value_text2'
                  : isMobile
                  ? 'contactUs_value_text2'
                  : ''
              "
              class="contactUs_value_text1 contactUs_value_text1_right"
              @click="onGroup(2)"
            >
              返回市公司
            </div>
            <div
              v-if="
                (logoTitle.companyLevel >= 5 && !isMobile) ||
                (logoTitle.companyLevel >= 5 && toParentType)
              "
              :class="
                logoTitle.companyLevel >= 5
                  ? 'contactUs_value_text2'
                  : isMobile
                  ? 'contactUs_value_text2'
                  : ''
              "
              class="contactUs_value_text1 contactUs_value_text1_right"
              @click="onGroup(3)"
            >
              返回区公司
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyList, getCompanyDetailById } from "@/api/index.js";
let startX, startY, endX, endY, deltaX, deltaY, angle;
export default {
  name: "App",
  data() {
    return {
      to: null,
      nav_id: "1",
      pageList: [
        {
          id: "1",
          text: "网站首页",
          path: "/",
        },
        {
          id: "2",
          text: "能源版块",
          path: "/EnergySector",
        },
        {
          id: "3",
          text: "药材版块",
          path: "/medicinalSector",
        },
        {
          id: "4",
          text: "基地中心",
          path: "/medicinal",
        },
        {
          id: "5",
          text: "数据中心",
          path: "/dataCenter",
        },
        {
          id: "6",
          text: "新闻中心",
          path: "/newsList",
        },
        {
          id: "7",
          text: "药材合集",
          path: "/Specific_m",
        },
        {
          id: "8",
          text: "企业介绍",
          path: "/introduction",
        },
      ],
      list: [
        {
          content: {
            content1: "企业介绍",
            content2: "能源版块",
            content3: "药材版块",
            content4: "基地中心",
            content5: "数据中心",
            url1: "/introduction",
            url2: "/EnergySector",
            url3: "/medicinalSector",
            url4: "/medicinal",
            url5: "/dataCenter",
            query1: {},
            query2: {},
            query3: {},
            query4: {},
            query5: {},
          },
        },
        {
          together: true,
          content: {
            content1: "药材合集",
            content2: "关药",
            content21: "北药",
            content3: "怀药",
            content31: "维药",
            content4: "浙药",
            content41: "海药",
            content5: "了解更多",
            url1: "/Specific_m",
            url2: "/Specific_m?type=1",
            url21: "/Specific_m?type=2",
            url3: "/Specific_m?type=3",
            url31: "/Specific_m?type=4",
            url4: "/Specific_m?type=5",
            url41: "/Specific_m?type=6",
            url5: "/Specific_m",
            query1: {},
            query2: { id: 0 },
            query3: { id: 2 },
            query4: { id: 4 },
            query5: {},
            query21: { id: 1 },
            query31: { id: 3 },
            query41: { id: 5 },
          },
        },
        {
          content: {
            content1: "新闻中心",
            content2: "新闻资讯",
            content3: "媒体资讯",
            content4: "行业资讯",
            content5: "热点专题",
            url1: "/newsList",
            url2: "/newsList?id=0",
            url3: "/newsList?id=1",
            url4: "/newsList?id=2",
            url5: "/newsList?id=3",
            query1: {},
            query2: { id: 0 },
            query3: { id: 1 },
            query4: { id: 2 },
            query5: { id: 3 },
          },
        },
        {
          content: {
            content1: "友情链接",
            content2: "中峪集团",
            content3: "中峪数交",
            content4: "中峪麒典",
            url1: "",
            url2: "https://www.zoneyu.cn/",
            url3: "https://www.zoneyu.net/",
            url4: "https://www.zoneyu.com.cn/",
            query1: { out: true },
            query2: { out: true },
            query3: { out: true },
            query4: { out: true },
          },
        },
      ],
      showNavigation: false,
      treeData: [],
      treeDataCopy1: [],
      treeDataCopy2: [],
      showContactUs: false,
      showContactUs1: false,
      showQRcode: false,
      observer: null,
      activeIndex: 0,
      titleType: 8,
      isMobile: false,
      logoTitle: {},
      toCompanyId: false,
      companyLevel: 1,
      showheadFoot: false,
      onjumpId: null,
      toParentType: false,
      isTopMenu: false,
      headerMenu: false,
    };
  },
  created() {
    this.logoTitle = this.$store.getters.getCompany || {};
  },
  mounted() {
    this.logoTitle = this.$store.getters.getCompany || {};
    if (this.logoTitle.companyLevel > 1) {
      this.showContactUs1 = true;
    }
    const userAgent = navigator.userAgent;
    const mobileKeywords = /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i;
    this.isMobile = mobileKeywords.test(userAgent);
    window.addEventListener("scroll", this.changeColor);
    if (this.isMobile) {
      window.addEventListener("touchstart", this.changeParentType);
      document.body.className = "imgPre";
    }
    this.observer = new IntersectionObserver(
      (entries) => {
        // 处理回调函数逻辑
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 元素进入视口的逻辑
            if (entry.target.className.indexOf("moveTopAnimation") == -1) {
              entry.target.className += " moveTopAnimation";
            }
          }
        });
      },
      {
        threshold: [0.1],
      }
    );
    // }
    setTimeout(() => {
      const targetElement = document.querySelectorAll(".moveTopNormal");
      targetElement.forEach((val) => {
        this.observer.observe(val);
      });
    }, 500);
    this.showheadFoot = true;
    // const mobileKeywords1 =  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // if (mobileKeywords1) {
    //   setTimeout(() => {
    //     this.getList();
    //   }, 2000);
    // } else {
    this.getList();
    // }
    //
    // window.addEventListener("touchstart", this.startImage);
    // window.addEventListener("touchmove", this.moveImage);
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.changeColor);
    // window.removeEventListener("touchstart", this.changeParentType);
  },
  methods: {
    startImage(event) {
      startX = event.touches[0].pageX;
      startY = event.touches[0].pageY;
    },
    moveImage(event) {
      if (event.target.className == "el-image-viewer__img") {
        const moveX = event.touches[0].pageX - startX;
        const moveY = event.touches[0].pageY - startY;
        if (Math.abs(moveX) > Math.abs(moveY)) {
          if (moveX > 0) {
            document.getElementsByClassName("el-image-viewer__prev")[0].click();
          } else {
            document.getElementsByClassName("el-image-viewer__next")[0].click();
          }
        }
      }
    },
    changeParentType(e) {
      if (e.target.className.indexOf("contactUs_value_text1") == -1) {
        this.toParentType = false;
      }
    },
    movebgImg(distance, index) {
      document.getElementsByClassName("company_title_outSide")[0].style[
        "background-position-x"
      ] = distance + "px";
      const list = document.querySelectorAll(".company_title_item");
      list.forEach((val, ind) => {
        if (ind == index) {
          val.style.color = "#fff";
        } else {
          val.style.color = "#000";
        }
      });
    },
    changeColor() {
      this.toParentType = false;
      if (window.scrollY > 20) {
        this.showNavigation = true;
        this.showContactUs1 = true;
        this.showContactUs = true;
        document.getElementsByClassName("navBox")[0] &&
          (document.getElementsByClassName("navBox")[0].style.top = this
            .isTopMenu
            ? (81 * window.innerWidth) / 1920 + "px"
            : "81px");
        document.getElementsByClassName("company_title")[0] &&
          (document.getElementsByClassName("company_title")[0].style.top = this
            .isTopMenu
            ? (80 * window.innerWidth) / 1920 + "px"
            : "80px");
        if (!this.headerMenu && !this.isMobile) {
          document.getElementsByClassName("header")[0].style =
            "opacity: 1;" +
            "transform: scale(" +
            (window.innerWidth > 1900 ? 1 : window.innerWidth / 1900) +
            ")";
          if (document.getElementsByClassName("header")[0]) {
            document.body.append(document.getElementsByClassName("header")[0]);
          }
          this.headerMenu = true;
        }
      } else {
        if (this.headerMenu && !this.isMobile) {
          const appChild = document.getElementById("app").children[0];
          if (document.getElementsByClassName("header")[0]) {
            document.getElementsByClassName("header")[0].style = "opacity: 1;";
            appChild.insertBefore(
              document.getElementsByClassName("header")[0],
              document.getElementById("app").children[0].children[0]
            );
          }
          this.headerMenu = false;
        }
        this.showContactUs1 = true;
        this.showContactUs = false;
        this.showNavigation = false;
        document.getElementsByClassName("navBox")[0] &&
          (document.getElementsByClassName("navBox")[0].style.top = this
            .isTopMenu
            ? (101 * window.innerWidth) / 1920 + "px"
            : "101px");
        document.getElementsByClassName("company_title")[0] &&
          (document.getElementsByClassName("company_title")[0].style.top = this
            .isTopMenu
            ? (100 * window.innerWidth) / 1920 + "px"
            : "100px");
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    getList() {
      getCompanyList({
        current: 1,
        size: 100,
        pid: this.logoTitle.companyId,
      }).then((res) => {
        this.treeData = [];
        let treeDataLevel1 = [];
        let treeDataLevel11 = [];
        res.data.list.forEach((val) => {
          if (val.companyCategory == 0) {
            treeDataLevel1.push(val);
          } else if (val.companyCategory == 1) {
            treeDataLevel11.push(val);
          } else {
            treeDataLevel1.push(val);
            treeDataLevel11.push(val);
          }
        });
        this.treeDataCopy1 = treeDataLevel1;
        this.treeDataCopy2 = treeDataLevel11;
      });
    },
    onGroup(index) {
      if (
        !this.toParentType &&
        this.isMobile &&
        this.logoTitle.companyLevel > 2
      ) {
        this.toParentType = true;
      } else {
        const companyTier = this.$store.getters.getCompanyTier;
        getCompanyDetailById(companyTier[index]).then((res) => {
          const company = res.data;
          this.$store.commit("setCompany", company);
          this.toParentType = false;
          setTimeout(() => {
            if (index == 0) {
              this.$router.push({ path: `/` });
            }
            if (index == 1) {
              this.$router.push({ path: `/ProvincialCompany` });
            }
            if (index == 2) {
              this.$router.push({ path: `/cityCompany` });
            }
             if (index == 3) {
              this.$router.push({ path: `/areaCompany` });
            }
            
            
          }, 500);
        });
      }
    },
    nav_click(x, path) {
      if (path == "medicinal") {
        if (
          document.getElementsByClassName("navigation")[0].style.height ==
          "auto"
        ) {
          document.getElementsByClassName("navigation")[0].style =
            "height: 0;z-index: -1";
          document.getElementsByClassName("navBox")[0].style.height = 0;
          document.getElementsByClassName("navBox")[0].style["z-index"] = -1;
          document.body.append(document.getElementsByClassName("navBox")[0]);
        } else {
          document.getElementsByClassName("navigation")[0].style =
            "height: auto;z-index: 9";
          document.getElementsByClassName("navBox")[0].style.height = "auto";
          document.getElementsByClassName("navBox")[0].style["z-index"] = 9;
        }
      }
      this.nav_id = x;
      if (x == 1) {
        if (this.logoTitle.companyLevel == 1) {
          if (this.$route.path == "/") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/` });
          }
        }
        if (this.logoTitle.companyLevel == 2) {
          if (this.$route.path == "/ProvincialCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/ProvincialCompany` });
          }
        }
        if (this.logoTitle.companyLevel == 3) {
          if (this.$route.path == "/cityCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/cityCompany` });
          }
        }
        if (this.logoTitle.companyLevel == 4) {
          if (this.$route.path == "/areaCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/areaCompany` });
          }
        }
        if (this.logoTitle.companyLevel == 5) {
          if (this.$route.path == "/projectCompany") {
            window.location.reload();
          } else {
            this.$router.push({ path: `/projectCompany` });
          }
        }
      } else {
        this.$router.push(path);
      }
    },
    toPage(type, company) {
      if (type == "home") {
        company = this.logoTitle;
      } else {
        this.$store.commit("setCompany", company);
      }
      if (company.companyLevel == 1) {
        if (this.$route.path == "/") {
          window.location.reload();
        } else {
          this.$router.push({ path: `/` });
        }
      }
      if (company.companyLevel == 2) {
        if (this.$route.path == "/ProvincialCompany") {
          window.location.reload();
        } else {
          this.$router.push({ path: `/ProvincialCompany` });
        }
      }
      if (company.companyLevel == 3) {
        if (this.$route.path == "/cityCompany") {
          window.location.reload();
        } else {
          this.$router.push({ path: `/cityCompany` });
        }
      }
      if (company.companyLevel == 4) {
        if (this.$route.path == "/areaCompany") {
          window.location.reload();
        } else {
          this.$router.push({ path: `/areaCompany` });
        }
      }
    },
    toPage1(path, query) {
      if (query.out) {
        if (path) {
          window.open(path, "_blank");
          return;
        }
        return;
      }
      if (path.indexOf(this.$route.path) > -1) {
        if (this.$route.path == "/" && path != "/") {
          this.$router.push({ path, query });
        } else {
          if ((query.id && query.id != this.$route.query.id) || query.id == 0) {
            this.$router.push({ path, query });
          } else {
            location.reload();
          }
        }
      } else {
        this.$router.push({ path, query });
      }
      document.getElementsByClassName("company_title")[0].style["z-index"] =
        "-1";
      document.getElementsByClassName("company_title")[0].style["opacity"] =
        "0";
    },
    toOtherPage(url) {
      window.open(url, "_blank");
    },
    mouseLeaveMenu() {
      document.getElementsByClassName("navigation")[0].style =
        "height: 0;z-index: 9";
      document.getElementsByClassName("navBox")[0].style.height = "0";
      document.getElementsByClassName("navBox")[0].style["z-index"] = 9;
      document.getElementsByClassName("company_title")[0].style["z-index"] =
        "-1";
      document.getElementsByClassName("company_title")[0].style["opacity"] =
        "0";
    },
    mouseOverMenu(item) {
      if (this.isMobile) {
        return;
      }
      if (item.id == 2) {
        this.treeData = this.treeDataCopy2;
      }
      if (item.id == 3) {
        this.treeData = this.treeDataCopy1;
      }
      if (item.id == 2 || item.id == 3) {
        if (
          document.getElementsByClassName("navBox")[0].style.height != "auto"
        ) {
          document.getElementsByClassName("navigation")[0].style =
            "height: auto;z-index: 9";
          document.getElementsByClassName("navBox")[0].style.height = "auto";
          document.getElementsByClassName("navBox")[0].style["z-index"] = 9;
          document.body.append(document.getElementsByClassName("navBox")[0]);
        }
        document.getElementsByClassName("company_title")[0].style["z-index"] =
          "-1";
        document.getElementsByClassName("company_title")[0].style["opacity"] =
          "0";
      } else if (item.id == 6) {
        if (!this.isMobile) {
          this.titleType = item.id;
          if (
            document.getElementsByClassName("company_title")[0].style[
              "z-index"
            ] == "-1"
          ) {
            document.getElementsByClassName("company_title")[0].style[
              "z-index"
            ] = "9";
            document.getElementsByClassName("company_title")[0].style[
              "opacity"
            ] = "1";
            document.body.append(
              document.getElementsByClassName("company_title")[0]
            );
          }
        }
      } else {
        document.getElementsByClassName("company_title")[0].style["z-index"] =
          "-1";
        document.getElementsByClassName("company_title")[0].style["opacity"] =
          "0";
      }
    },
    mouseLeaveMenu1() {
      document.getElementsByClassName("navigation")[0].style =
        "height: 0;z-index: 9";
      document.getElementsByClassName("navBox")[0].style.height = "0";
      document.getElementsByClassName("navBox")[0].style["z-index"] = 9;
      if (window.scrollY > 100) {
        document.getElementsByClassName("header")[0].className =
          "header header_bg_color";
      } else {
        document.getElementsByClassName("header")[0].className = "header";
      }
    },
    mouseOverMenu1() {
      if (document.getElementsByClassName("navBox")[0].style.height != "auto") {
        document.getElementsByClassName("navigation")[0].style =
          "height: auto;z-index: 9";
        document.getElementsByClassName("navBox")[0].style.height = "auto";
        document.getElementsByClassName("navBox")[0].style["z-index"] = 9;
        document.body.append(document.getElementsByClassName("navBox")[0]);
        document.getElementsByClassName("header")[0].className +=
          " head_isActive";
      }
    },
    mouseLeaveMenu2() {
      document.getElementsByClassName("company_title")[0].style["z-index"] =
        "-1";
      document.getElementsByClassName("company_title")[0].style["opacity"] =
        "0";
      if (window.scrollY > 100) {
        document.getElementsByClassName("header")[0].className =
          "header header_bg_color";
      } else {
        document.getElementsByClassName("header")[0].className = "header";
      }
    },
    mouseOverMenu2() {
      if (
        document.getElementsByClassName("company_title")[0].style["z-index"] ==
        "-1"
      ) {
        document.getElementsByClassName("company_title")[0].style["z-index"] =
          "9";
        document.getElementsByClassName("company_title")[0].style["opacity"] =
          "1";
        document.body.append(
          document.getElementsByClassName("company_title")[0]
        );
        document.getElementsByClassName("header")[0].className +=
          " head_isActive";
      }
    },
    getComDetail() {
      getCompanyDetailById(this.logoTitle.companyId).then((res) => {
        // res.data.listingStatus == 0 下架
        if (res.data.listingStatus == 0) {
          this.$router.push("/");
        }
      });
    },
  },
  watch: {
    $route(to) {
      document.body.style = "";
      const appChild = document.getElementById("app").children[0];
      if (document.getElementsByClassName("header")[0]) {
        appChild.insertBefore(
          document.getElementsByClassName("header")[0],
          document.getElementById("app").children[0].children[0]
        );
      }
      setTimeout(() => {
        if (document.getElementsByClassName("header")[0]) {
          if (window.scrollY > 100) {
            document.getElementsByClassName("header")[0].className =
              "header header_bg_color";
          } else {
            document.getElementsByClassName("header")[0].className = "header";
          }
        }
      }, 200);
      const userAgent = navigator.userAgent;
      const phone = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
      ].some((keyword) => userAgent.includes(keyword));

      if (window.innerWidth < 1920) {
        if (to.path != "/dataCenter") {
          setTimeout(() => {
            if (document.getElementsByClassName("contactUs")[0]) {
              document.body.append(
                document.getElementsByClassName("contactUs")[0]
              );
            }
            if (document.getElementsByClassName("contactUs1")[0]) {
              document.body.append(
                document.getElementsByClassName("contactUs1")[0]
              );
            }
          }, 500);
          if (
            window.screen.width < 1000 &&
            window.location.href.indexOf("dataCenter") == -1
          ) {
            const meta = document.querySelector('meta[name="viewport"]');
            meta.setAttribute(
              "content",
              "width=device-width, initial-scale=" + phone
                ? window.screen.width / 1900
                : window.innerWidth / 1920
            );
            if (!phone) {
              document.getElementById("app").style = "transform: scale(0.9)";
            } else {
              document.getElementById("app").style = "transform: scale(0.75)";
            }
          } else {
            const meta = document.querySelector('meta[name="viewport"]');
            meta.setAttribute(
              "content",
              "width=device-width, initial-scale=1.0"
            );
            if (!/Mac|iPad|iPhone|iPod/.test(navigator.userAgent)) {
              setTimeout(() => {
                if (/firefox/i.test(navigator.userAgent)) {
                  document.getElementById("app").childNodes[0].style =
                    "transform: scale(" + window.screen.width / 1900 + ")";
                  // if(to.path== '/'){
                  //   document.getElementById('app').style.height = 'calc(10344 * 100vw / 1920)';
                  // } else if(to.path== '/introduction'){
                  //   document.getElementById('app').style.height =  'calc(6646 * 100vw / 1920)'
                  // } else if(to.path== '/EnergySector'){
                  //   document.getElementById('app').style.height =  'calc(5183 * 100vw / 1920)'
                  // } else if(to.path== '/medicinalSector'){
                  //   document.getElementById('app').style.height =  'calc(5683 * 100vw / 1920)'
                  // } else if(to.path== '/medicinal'){
                  //   document.getElementById('app').style.height =  'calc(2524 * 100vw / 1920)'
                  // } else if(to.path== '/projectDetail'){
                  //   document.getElementById('app').style.height =  'calc(8020 * 100vw / 1920)'
                  // } else if(to.path== '/Specific_m'){
                  //   document.getElementById('app').style.height =  'calc(3074 * 100vw / 1920)'
                  // } else if(to.path== '/medicineDetails'){
                  //   document.getElementById('app').style.height =  'calc(4456 * 100vw / 1920)'
                  // } else if(to.path== '/newsListDetail'){
                  //   document.getElementById('app').style.height =  'auto'
                  // }
                } else {
                  document.getElementById("app").style =
                    "transform: scale(" + window.screen.width / 1900 + ")";
                }
              }, 200);
              const all = document.querySelectorAll(".topMenu");
              this.isTopMenu = true;
              all.forEach((val) => {
                const top = window
                  .getComputedStyle(val)
                  .getPropertyValue("top");
                val.style.top =
                  Number(top.replace("px", "")) * (window.innerWidth / 1920) +
                  "px";
              });
            }
          }
        } else {
          const meta = document.querySelector('meta[name="viewport"]');
          meta.setAttribute("content", "width=device-width, initial-scale=1.0");
          document.getElementById("app").style =
            "transform: scale(1);min-width: auto";
          if (
            !/Mac|iPad|iPhone|iPod/.test(navigator.userAgent) &&
            !this.isMobile
          ) {
            if (/firefox/i.test(navigator.userAgent)) {
              document.getElementById("app").childNodes[0].style =
                "transform: scale(" + window.screen.width / 1900 + ")";
            } else {
              document.getElementById("app").style =
                "transform: scale(" + window.screen.width / 1900 + ")";
            }
            const all = document.querySelectorAll(".topMenu");
            this.isTopMenu = true;
            all.forEach((val) => {
              const top = window.getComputedStyle(val).getPropertyValue("top");
              val.style.top =
                Number(top.replace("px", "")) * (window.innerWidth / 1920) +
                "px";
            });
          }
        }
      }

      setTimeout(() => {
        if (to.path == "/dataCenter") {
          document.getElementsByClassName("navBox")[0] &&
            document.getElementsByClassName("navBox")[0].remove();
          document.getElementsByClassName("header")[0] &&
            document.getElementsByClassName("header")[0].remove();
          document.getElementsByClassName("company_title")[0] &&
            document.getElementsByClassName("company_title")[0].remove();
          document.getElementsByClassName("contactUs")[0] &&
            document.getElementsByClassName("contactUs")[0].remove();
          document.getElementsByClassName("contactUs1")[0] &&
            document.getElementsByClassName("contactUs1")[0].remove();
        }
        const targetElement = document.querySelectorAll(".moveTopNormal");
        targetElement.forEach((val) => {
          this.observer.observe(val);
        });
        if (document.getElementsByClassName("header")[0]) {
          document.getElementsByClassName("header")[0].style = "opacity: 1";
          document.getElementsByClassName("foot_")[0].style.opacity = 1;
        }
        this.logoTitle = this.$store.getters.getCompany;
        if (this.logoTitle.companyLevel != 1) {
          this.toCompanyId = true;
        } else {
          this.toCompanyId = false;
        }
        if (
          this.logoTitle.companyLevel == 4 ||
          this.logoTitle.companyLevel == 5
        ) {
          this.pageList = [
            {
              id: "1",
              text: "网站首页",
              path: "/",
            },
            {
              id: "4",
              text: "基地中心",
              path: "/medicinal",
            },
            {
              id: "5",
              text: "数据中心",
              path: "/dataCenter",
            },
            {
              id: "6",
              text: "新闻中心",
              path: "/newsList",
            },
            {
              id: "7",
              text: "药材合集",
              path: "/Specific_m",
            },
            {
              id: "8",
              text: "企业介绍",
              path: "/introduction",
            },
          ];
        } else {
          this.pageList = [
            {
              id: "1",
              text: "网站首页",
              path: "/",
            },
            {
              id: "2",
              text: "能源版块",
              path: "/EnergySector",
            },
            {
              id: "3",
              text: "药材版块",
              path: "/medicinalSector",
            },
            {
              id: "4",
              text: "基地中心",
              path: "/medicinal",
            },
            {
              id: "5",
              text: "数据中心",
              path: "/dataCenter",
            },
            {
              id: "6",
              text: "新闻中心",
              path: "/newsList",
            },
            {
              id: "7",
              text: "药材合集",
              path: "/Specific_m",
            },
            {
              id: "8",
              text: "企业介绍",
              path: "/introduction",
            },
          ];
        }
        this.getList();
      }, 500);
      this.getComDetail();
      let path = to.path;
      if (path[path.length - 1] == "/") {
        path = path.substring(0, path.length - 1);
      }
      switch (path) {
        case "":
          this.nav_id = 1;
          break;
        case "/":
          this.nav_id = 1;
          break;
        case "/EnergySector":
          this.nav_id = 2;
          break;
        case "/medicinal":
          this.nav_id = 4;
          break;
        case "/medicinalSector":
          this.nav_id = 3;
          break;
        case "/ProvincialCompany":
          this.nav_id = 1;
          break;
        case "/cityCompany":
          this.nav_id = 1;
          break;
        case "/areaCompany":
          this.nav_id = 1;
          break;
        case "/baseDetail":
          this.nav_id = 4;
          break;
        case "/baseCompanyDetail":
          this.nav_id = 4;
          break;
        case "/projectDetail":
          this.nav_id = 4;
          break;
        case "/landDetail":
          this.nav_id = 4;
          break;
        case "/dataCenter":
          this.nav_id = 5;
          break;
        case "/newsList":
          this.nav_id = 6;
          break;

        case "/newsListDetail":
          this.nav_id = 6;
          break;
        case "/Specific_m":
          this.nav_id = 7;
          break;
        case "/introduction":
          this.nav_id = 8;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  min-width: 1900px;
  width: 100%;
  transform-origin: 0 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: background-color 0.5s ease;
  opacity: 0;

  .header_bg {
    display: flex;
    z-index: 99;
    align-items: center;
    position: static;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    background-color: rgba(19, 36, 16, 0.26);
    transition: all 0.5s ease;
  }

  .header-img {
    background-image: url("../static/newImg2/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-left: 30px;
  }

  .header_img1 {
    background-image: url("../static/image/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    height: 75px;
    width: 250px;
    margin-left: 30px;
  }

  .header_title {
    font-family: kaiti;
    cursor: pointer;
    font-size: 36px;
    color: #fff;
    font-weight: 600;
    margin-left: 15px;
  }

  .header-center {
    width: 900px;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    line-height: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .header-text-outside {
      height: 100%;
      transition: all 0.5s;
    }

    div {
      max-width: 100px;
    }

    .header-text {
      width: 100px;
      height: 100%;
      text-align: center;
      margin: 0 15px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      color: #cbcbcb;
      position: relative;
      overflow: hidden;
    }

    .header-text-border {
      font-size: 16px;
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }

    .header-text::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      background-color: #1e9409;
      height: 2px;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      border-radius: 2px;
    }
  }

  .header_phone {
    width: 250px;
    height: 44px;
    border-left: 1px solid #fff6;
    font-size: 14px;
    color: #ffffffa1;
    line-height: 22px;
    align-items: center;
    text-align: center;
    margin-left: 40px;
    cursor: pointer;
  }
}

.header:hover,
.head_isActive {
  background-color: #fff;

  .header_bg {
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
  }

  .header-img {
    background-image: url("../static/newImg2/logo.png");
  }

  .header_img1 {
    background-image: url("../static/newImg2/logo1.png");
    // background-image: url("../static/image/logo.png");
  }

  .header_title {
    color: #000;
  }

  .header-center {
    .header-text {
      color: #000;
    }

    .header-text-border {
      color: #000;
    }
  }

  .header_phone {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    color: #000;
  }
}

.header-text-outside:hover {
  .header-text {
    color: #1e9409 !important;
  }

  .header-text::after {
    width: 100px;
  }
}

.header_bg_color {
  .header_bg {
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    height: 80px !important;
  }

  .header-img {
    background-image: url("../static/newImg2/logo.png");
  }

  .header_img1 {
    // background-image: url("../static/image/logo.png");
    background-image: url("../static/newImg2/logo1.png");
  }

  .header_title {
    color: #000;
  }

  .header-text-outside {
    line-height: 80px;
  }

  .header-text-outside-active {
    .header-text {
      color: #1e9409 !important;
    }

    .header-text::after {
      width: 100px;
    }
  }

  .header-center {
    .header-text {
      color: #000;
    }

    .header-text-border {
      color: #000;
      // border-bottom: 1px solid #000;
    }
  }

  .header_phone {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    color: #000;
  }
}

@media (max-width: 767px) {
  .navBox {
    transform: scale(1);
    top: calc(100vw / 1900 * 101) !important;
    transform-origin: 0 0;
  }
}
.company_title {
  top: calc(100 * 100vw / 1920);
}

.navBox {
  top: calc(101 * 100vw / 1920);
  transform-origin: 0 0;
  left: 0;
  z-index: 9;
  width: 100%;
}

.navigation {
  background: url("../static/image/head_bg1.png") no-repeat;
  background-size: contain;
  background-position: 0 bottom;
  position: absolute;
  left: 0;
  width: 1900px;
  padding: 0px calc(50% - 640px);
  height: 0;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  z-index: 9;
  justify-content: flex-start;
  transition: height 0.8s ease-out;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);

  overflow-y: scroll;

  .navigation_list {
    width: 360px;
    margin-left: 50px;
    padding: 60px 0;

    .fontSize {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 20px;
      font-weight: 900;
      white-space: nowrap;
      cursor: pointer;
    }

    .home_four_module_bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 360px;
      height: 200px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.71) 100%
      );
    }

    img {
      cursor: pointer;
      width: 360px;
      height: 200px;
      object-fit: cover;
    }

    .navigation_list_title {
      margin-top: 20px;
      width: 320px;
      height: 56px;
      background: rgba(203, 203, 203, 0.1);
      line-height: 56px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 400;
      color: #354a32;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .navigation_name {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;

      .navigation_name_item {
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        line-height: 30px;
        margin-top: 10px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.foot_ {
  width: 100%;
  background: url("../static/v1.0/foot.png") no-repeat;
  background-size: cover;
  opacity: 0;

  .foot_top_box {
    height: 420px;
    width: 1600px;
    margin: 0 auto;

    .foot_top_box_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .foot_center_left_box {
        display: flex;
        width: calc(40% - 1px);
        border-left: 1px solid rgba(255, 255, 255, 0.12);
        justify-content: flex-end;

        .foot_center_left_box_left {
          width: 122px;
        }

        .foot_center_left_box_right {
          width: 50%;
          padding-top: 50px;
          margin-left: 40px;

          .foot_center_left_box_right_title {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.55);
            line-height: 21px;
            margin-bottom: 20px;
          }

          .foot_center_left_box_right_value {
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;
            margin-bottom: 45px;
          }
        }
      }

      .foot_center_box {
        height: calc(100% - 100px);
        width: 60%;
        display: flex;
        padding-top: 100px;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            color: rgba(255, 255, 255, 0.56);
            cursor: pointer;
            font-size: 16px;
            line-height: 32px;
            margin: 0 150px 8px 0;
          }

          .link {
            span {
              display: inline-block;
            }

            span:last-child {
              margin-left: 5px;
            }

            span:first-child {
              margin-left: 0;
            }

            span:hover {
              color: #ffffff;
            }
          }
        }
      }

      .foot_center_box_head {
        height: 26px;
        margin-bottom: 8px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }

  .foot_logo {
    background-image: url("../static/image/foot_logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100px;
    height: 122px;
  }

  .foot_bottom_box {
    background: #11100f;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 80px;
  }
}

.contactUs {
  position: fixed;
  z-index: 999;
  transform: translateY(-50%);
  top: 50vh;
  right: 50px;
  display: flex;

  .contactUs_right {
    .contactUs_value {
      position: relative;

      .contactUs_value_text {
        cursor: pointer;
        writing-mode: vertical-rl;
        padding: 16px 8px 12px 8px;
        background: #48b83c;
        box-shadow: 0px 2px 5px 0px rgba(156, 156, 156, 0.5);
        border-radius: 28px;
        border: 3px solid #ffffff;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        letter-spacing: 8px;
      }

      .contactUs_left {
        position: absolute;
        top: -20px;
        left: -230px;
        background-color: #fff;
        box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: solid 2px #ffffff;
        padding: 10px 20px;
        font-size: 16px;
        transition: all 0.5s;
        opacity: 1;

        .contactUs_left_name {
          color: #333333;
          margin-bottom: 10px;
        }

        .contactUs_left_value {
          color: #136a2c;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ddd;
        }

        .contactUs_left_tips {
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .contactUs_top {
      width: 40px;
      height: 40px;
      background: #f8faf8;
      box-shadow: 0px 2px 5px 0px rgba(156, 156, 156, 0.5);
      border-radius: 28px;
      border: 3px solid #ffffff;
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
@media (max-width: 767px) {
  .contactUs {
    right: 10px;
  }
}

.contactUs1 {
  position: fixed;
  z-index: 999;
  transform: translateY(-50%);
  top: 50vh;
  right: 0;
  display: flex;

  .contactUs_right1 {
    .contactUs_value1 {
      position: relative;
      height: 130px;
      display: flex;

      .contactUs_value_text1 {
        width: 40px;
        height: 120px;
        cursor: pointer;
        padding: 16px 8px 12px 8px;
        box-shadow: 0px 2px 5px 0px rgba(156, 156, 156, 0.5);
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        letter-spacing: 6px;
        line-height: 25px;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        background: linear-gradient(180deg, #478a3f 0%, #3d9834 100%);
        border-radius: 28px 0px 0px 28px;
        border: 4px solid rgba(0, 0, 0, 0.15);
        border-right: 0;
        padding-left: 20px;
        font-family: 楷体;
        opacity: 0;
      }

      .contactUs_value_text2 {
        width: 0;
        transition: all 0.5s;
      }

      .contactUs_value_text2 {
        border-radius: 0;
        border-left: 0;
        padding-right: 0;
        padding-left: 0;
        letter-spacing: 6px;
      }

      .contactUs_left1 {
        position: absolute;
        top: -20px;
        left: -230px;
        background-color: #fff;
        box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: solid 2px #ffffff;
        padding: 10px 20px;
        font-size: 16px;
        transition: all 0.5s;
        opacity: 1;

        .contactUs_left_name1 {
          color: #333333;
          margin-bottom: 10px;
        }

        .contactUs_left_value1 {
          color: #136a2c;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ddd;
        }

        .contactUs_left_tips1 {
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    .contactUs_value1:hover {
      .contactUs_value_text2 {
        width: 45px;
        padding-left: 20px;
        opacity: 1;
      }
    }

    .contactUs_top1 {
      width: 40px;
      height: 40px;
      background: #f8faf8;
      box-shadow: 0px 2px 5px 0px rgba(156, 156, 156, 0.5);
      border-radius: 28px;
      border: 3px solid #ffffff;
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
.contactUs_phone {
  transform-origin: right;
  .contactUs_value_text2 {
    width: 40px !important;
    padding-left: 20px !important;
  }
}

@-moz-document url-prefix() {
  .header {
    width: 1920px !important;
  }
  .foot_ {
    width: 1920px !important;
  }
}
</style>
