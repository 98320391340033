import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/projectDetail",
      name: "projectDetail",
      component: () => import("@/view/projectDetail.vue"),
    },
    {
      path: "/introduction",
      name: "introduction",
      component: () => import("@/view/v1.0/companyProfile.vue"),
    },
    {
      path: "/newsList",
      name: "newsList",
      component: () => import("@/view/newsList.vue"),
    },
    {
      path: "/newsListDetail",
      name: "newsListDetail",
      component: () => import("@/view/newsListDetail.vue"),
    },
    {
      path: "/",
      name: "",
      component: () => import("@/view/v1.0/index.vue"),
    },
    //第四层公司
    {
      path: "/projectCompany",
      name: "projectCompany",
      component: () => import("@/view/v1.0/projectCompany.vue"),
    },

    //省公司
    {
      path: "/ProvincialCompany",
      name: "ProvincialCompany",
      component: () => import("@/view/v1.0/ProvincialCompany.vue"),
    },
    //省公司
    {
      path: "/cityCompany",
      name: "cityCompany",
      component: () => import("@/view/v1.0/cityCompany.vue"),
    },
    //能源版块
    {
      path: "/EnergySector",
      name: "EnergySector",
      component: () => import("@/view/v1.0/EnergySector.vue"),
    },
    //药材版块
    {
      path: "/medicinalSector",
      name: "medicinalSector",
      component: () => import("@/view/v1.0/medicinalSector.vue"),
    },
    //药材合集
    {
      path: "/Specific_m",
      name: "Specific_m",
      component: () => import("@/view/v1.0/Specific_m.vue"),
    },
    //药材详情
    {
      path: "/medicineDetails",
      name: "medicineDetails",
      component: () => import("@/view/v1.0/medicineDetails.vue"),
    },
    //药材详情
    {
      path: "/medicinal",
      name: "medicinal",
      component: () => import("@/view/v1.0/medicinal.vue"),
    },
    //区域
    {
      path: "/areaCompany",
      name: "areaCompany",
      component: () => import("@/view/v1.0/areaCompany.vue"),
    },

    // 关于我们
    {
      path: "/companyProfile",
      name: "companyProfile",
      component: () => import("@/view/v1.0/companyProfile.vue"),
    },
    // 数据中心
    {
      path: "/dataCenter",
      name: "dataCenter",
      component: () => import("@/view/dataCenter.vue"),
    },
  ],
});
export default router;
